import './SurfApplicationContent.css'

const SurfApplicationContent = () => {
    return (
        <main>
       <iframe className={"surfApplicationContentContainer"} src="https://dev.io-academy.uk/projects/2022-july/surfwave-fury/" width="1200px" height="900px"/>
    </main>
    )
}

export default SurfApplicationContent